import React, { useState } from 'react';
import useCurrencyData from '../hooks/useCurrencyData';

const CurrencyConverter = () => {
  const { currencies, loading } = useCurrencyData();
  const [amount, setAmount] = useState('');
  const [fromCurrency, setFromCurrency] = useState('USD'); // Loại tiền tệ đầu vào mặc định
  const [toCurrency, setToCurrency] = useState('VND'); // Loại tiền tệ đầu ra mặc định
  const [convertedAmount, setConvertedAmount] = useState(null);

  const handleConvert = () => {
    // Tìm thông tin của các loại tiền tệ đã chọn
    const fromCurrencyData = currencies.find((c) => c.code === fromCurrency);
    const toCurrencyData = toCurrency === 'VND' ? { code: 'VND', buy: 1 } : currencies.find((c) => c.code === toCurrency);

    if (!fromCurrencyData || !toCurrencyData) return;

    // Tính tỷ giá chuyển đổi từ "fromCurrency" sang "toCurrency"
    const vndAmount = fromCurrency === 'VND' ? amount : amount * fromCurrencyData.buy; // Chuyển từ tiền tệ đầu vào sang VND
    const result = toCurrency === 'VND' ? vndAmount : vndAmount / toCurrencyData.buy;  // Chuyển từ VND sang tiền tệ đầu ra

    setConvertedAmount(result);
  };

  const formatCurrency = (value, currency) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(value);
  };

  if (loading) return <p>Đang tải dữ liệu...</p>;

  return (
    <div className="currency-converter">
      <h2>Chuyển đổi tiền tệ</h2>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Nhập số tiền"
        min="0"
        className="input-amount"
      />
      <div className="currency-selection">
        <label>
          Từ:
          <select
            value={fromCurrency}
            onChange={(e) => setFromCurrency(e.target.value)}
            className="currency-select"
          >
            {currencies.map((currency) => (
              <option key={currency.code} value={currency.code}>
                {currency.name} ({currency.code})
              </option>
            ))}
          </select>
        </label>
        <label>
          Sang:
          <select
            value={toCurrency}
            onChange={(e) => setToCurrency(e.target.value)}
            className="currency-select"
          >
            <option value="VND">Việt Nam Đồng (VND)</option>
            {currencies.map((currency) => (
              <option key={currency.code} value={currency.code}>
                {currency.name} ({currency.code})
              </option>
            ))}
          </select>
        </label>
      </div>
      <button onClick={handleConvert} className="convert-button">Chuyển đổi</button>
      {convertedAmount !== null && (
        <div className="result-box">
          <p>Kết quả: {formatCurrency(convertedAmount, toCurrency)}</p>
        </div>
      )}
    </div>
  );
};

export default CurrencyConverter;
