import axios from "axios";
import { parseStringPromise } from "xml2js";

const API_URL =
  "https://portal.vietcombank.com.vn/Usercontrols/TVPortal.TyGia/pXML.aspx?b=10";

export const fetchCurrencyData = async () => {
  try {
    const response = await axios.get(API_URL);
    const xmlData = response.data;

    // Parse XML to JSON
    const jsonData = await parseStringPromise(xmlData);
    return jsonData.ExrateList.Exrate.map((rate) => ({
      code: rate.$.CurrencyCode,
      name: rate.$.CurrencyName.trim(),
      buy: parseFloat(rate.$.Buy.replace(",", "")) || "-",
      transfer: parseFloat(rate.$.Transfer.replace(",", "")),
      sell: parseFloat(rate.$.Sell.replace(",", "")),
    }));
  } catch (error) {
    console.error("Error fetching currency data:", error);
    return [];
  }
};
