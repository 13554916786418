import { useState, useEffect } from 'react';
import { fetchCurrencyData } from '../api/currencyService';

const useCurrencyData = () => {
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await fetchCurrencyData();
      setCurrencies(data);
      setLoading(false);
    };

    getData();
  }, []);

  return { currencies, loading };
};

export default useCurrencyData;
