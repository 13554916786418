import React from "react";
import CurrencyConverter from "./components/CurrencyConverter";
import CurrencyList from "./components/CurrencyList";
import "./App.css";

const App = () => (
  <div className="App">
    <header className="App-header">
  <div className="App-header-content">
    {/* Updated logo section */}
    <div className="App-logo">
      {/* <img src="your-logo-url.png" alt="Currency Converter Logo" className="logo-image" /> Replace 'your-logo-url.png' with your actual logo image URL */}
    </div>
    {/* Updated title with a subtitle in Vietnamese */}
    <div className="App-title">
      <h1 style={{ textAlign: "center" }}>Chuyển Đổi Tiền Tệ</h1>
      <p className="App-subtitle">Công Cụ Tỷ Giá & Chuyển Đổi</p>
    </div>
  </div>
</header>
    <main className="container">
      <CurrencyConverter />
      <CurrencyList />
    </main>
    <footer className="App-footer">
      <p>Mini App © 2024 - All rights reserved.</p>
    </footer>
  </div>
);

export default App;
