import React from 'react';
import useCurrencyData from '../hooks/useCurrencyData';

const CurrencyList = () => {
  const { currencies, loading } = useCurrencyData();

  if (loading) return <p>Đang tải dữ liệu...</p>;

  const formatCurrency = (value, currency) => {
    if (isNaN(value)) return "-";
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(value);
  };

  return (
    <div className="currency-list">
      <h2 className="currency-list-title">Tỷ giá hối đoái</h2>
      <table className="currency-table">
        <thead>
          <tr>
            <th>Loại tiền</th>
            <th>Mua vào</th>
            <th>Chuyển khoản</th>
            <th>Bán ra</th>
          </tr>
        </thead>
        <tbody>
          {currencies.map((currency) => (
            <tr key={currency.code}>
              <td>{currency.name}</td>
              <td>{formatCurrency(parseFloat(currency.buy),'VND') || "-"}</td>
              <td>{formatCurrency(parseFloat(currency.transfer),'VND') || "-"}</td>
              <td>{formatCurrency(parseFloat(currency.sell),'VND')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CurrencyList;
